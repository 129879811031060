import * as React from 'react';
import { Modal, Typography } from 'antd';
import type { UserPracticeConnectionMinimalFragment } from '@zavy360/graphql/operations';
import useSwitchUser from '@zavy360/hooks/useSwitchUser';
import { useCallback } from '@zavy360/hooks/react';
import { useApolloClient } from '@apollo/client/react';

export interface ISwitchedSessionOverlayProps {
  upc: UserPracticeConnectionMinimalFragment;
}

export function SwitchedSessionOverlay(props: ISwitchedSessionOverlayProps) {
  const { upc } = props;
  const switchUser = useSwitchUser();

  const onOk = useCallback(() => {
    if (upc?.guid) {
      switchUser(upc.guid, true);
    }
  }, [switchUser, upc]);
  return (
    <Modal
      open
      closable={false}
      cancelButtonProps={{
        // Set this here instead of onCancel,
        // otherwise clicking outside of the dialog
        // will cause a reload instead of doing nothing
        onClick: () => window.location.reload()
      }}
      onCancel={() => null}
      {...{ onOk }}
      title='Login Changed'
      okText='Switch back'
      cancelText='Reload'
      mask
      styles={{ mask: { backdropFilter: 'blur(16px)' } }}
    >
      <Typography.Paragraph>
        This session was logged in as {upc?.profile?.fullName}, but you've switched to another profile. Switch back to
        access this page again, or reload the page to use the new login.
      </Typography.Paragraph>
    </Modal>
  );
}
