import type { LazyQueryHookOptions, LazyQueryResultTuple } from '@apollo/client';
import config from '@zavy360/config';
import { useCallback, useEffect, useMemo, useState } from '@zavy360/hooks/react';
import debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import { startTransition, useRef } from 'react';
import { createDelayedDebugLogger } from '@zavy360/utils/debug';
import type { DebounceSettings } from 'lodash';
import { useQueryVariables } from './useQueryVariables';
import { type IUseInfiniteScrollOpts, useInfiniteScroll } from './useInfiniteScroll';
import { useTabVisibility } from '@zavy360/hooks/device';
import { DateTime } from 'luxon';

export interface IUseLazyQueryEffectOpts<Query, Variables> {
  // Whether to print debug logging for this query
  debug?: boolean;
  // Condition that if set to false, will block any refetching
  // until the condition becomes true
  skip?: boolean;
  // Give the hook a custom name (for debugging purposes)
  name?: string;
  // Fetch policy to use when refetching
  fetchPolicy?: LazyQueryHookOptions<Query, Variables>['fetchPolicy'];

  // Don't perform a search again within this timeperiod (in ms)
  debounce?: DebounceSettings & { wait: number };

  pollInterval?: number;

  // Should this be cancellable, and cancel when
  // variables change? (this means it cant be batched)
  cancellable?: boolean;

  // Infinite scroll options
  infiniteScroll?: {
    getPageInfo(data: Query): { hasNextPage?: boolean; endCursor?: string | null };
  } & IUseInfiniteScrollOpts<Query, Variables>;

  // Called before the query refetches
  onBeforeRefetch?(): void;
  // Called when the query has been refetched
  onAfterRefetch?(): void;
  // Called if the query fails to refetch
  onRefetchError?(e: Error): void;

  onCompleted?(data: Query): void;
  isEqual?: typeof _isEqual;
}

// biome-ignore lint/suspicious/noExplicitAny: Allowed for type inference
const DEFAULT_OPTIONS: IUseLazyQueryEffectOpts<any, any> = {
  debug: false || config.apollo.hooks.lazyQueryEffect.debug,
  onBeforeRefetch: undefined,
  onAfterRefetch: undefined,
  onRefetchError: undefined,
  debounce: undefined,
  infiniteScroll: undefined,
  onCompleted: undefined,
  skip: false,
  // fetchPolicy: 'cache-and-network',
  isEqual: _isEqual
};

const log = createDelayedDebugLogger();

/**
 * In a lot of places we have a useEffect that checks
 * whether variables have changes for a lazy query,
 * and then refetches it. This is done *slightly* different
 * in a lot of places, and we've had issues where this is done
 * in a way that doesn't actually fetch the query correctly.
 *
 * This hook provides memoization of variables *and* an effect to
 * refetch when the variables change, and can be used like this:
 *
 * const [getSomething, query] = useSomethingLazyQuery();
 * useLazyQueryEffect(getSomething, query.variables, { id: something.id })
 *
 * If you need to do something else on top of this, provide the onRefetch
 * option. Debugging can be enabled on a global level in 'config/AppConfig'
 * by setting `apollo.hooks.lazyQueryEffect.debug` to true,
 * or by passing { debug: true } in the options
 */
export function useLazyQueryEffect<Variables extends { [key: string]: unknown }, Query extends object>(
  hookOrResult:
    | LazyQueryResultTuple<Query, Variables>
    | ((baseOptions?: LazyQueryHookOptions<Query, Variables>) => LazyQueryResultTuple<Query, Variables>),
  queryVariables: Variables,
  opts: IUseLazyQueryEffectOpts<Query, Variables> = DEFAULT_OPTIONS
) {
  // Set up the default options
  const {
    isEqual,
    debug,
    skip,
    debounce: debounceSettings,
    infiniteScroll: infiniteScrollOptions,
    fetchPolicy,
    name,
    cancellable,
    pollInterval,
    onCompleted,
    onAfterRefetch,
    onBeforeRefetch,
    onRefetchError
  } = useMemo(() => ({ ...DEFAULT_OPTIONS, ...opts }), [opts]);
  const abortController = useRef<AbortController>();
  const [lastFetchedAt, setLastFetchedAt] = useState(null);
  const [fetchQuery, ctx] = typeof hookOrResult === 'function' ? hookOrResult({ onCompleted }) : hookOrResult;
  const { loading, called } = ctx;
  const currentVariables = ctx.variables;
  // Memoize the variables
  const normalizedCurrentVariables = useQueryVariables(currentVariables);
  const normalizedIncomingVariables = useQueryVariables(queryVariables);
  const hookName = name || fetchQuery?.name || 'Anonymous';
  // Error object to pass in context so that AppSignal can
  // use the stacktrace from the error to see where the query
  // was called from. We have to call it in the main function body
  // for the trace to be accurate
  const error = useRef<Error>(new Error(hookName));

  const { getPageInfo, ...infiniteScrollRestOpts } = infiniteScrollOptions || { usePageInfo: noop };
  const infiniteScroll = useInfiniteScroll(ctx, getPageInfo?.(ctx?.data) || { hasNextPage: false, endCursor: null }, {
    name: hookName,
    debug,
    ...infiniteScrollRestOpts
  });

  const [tabVisibility, tabVisibilityRef] = useTabVisibility();

  const refetchQuery = useCallback(
    async function RefetchLazyQuery(variables: Variables) {
      try {
        if (skip) {
          return;
        }
        onBeforeRefetch?.();
        log(`[Apollo::Hooks::useLazyQueryEffect::${hookName}::refetchQuery]: Fetching with variables`, {
          variables
        });
        // Create a new AbortController to cancel this query
        if (abortController.current?.abort && loading && cancellable) {
          log(`[Apollo::Hooks::useLazyQueryEffect::${hookName}::refetchQuery]: Aborting previous query`);
          // abortController.current.abort();
        }
        const controller = new AbortController();
        abortController.current = controller;
        // console.debug({
        //   initialFetchPolicy: 'cache-and-network',
        //   fetchPolicy: !called ? 'cache-and-network' : fetchPolicy || 'cache-first',
        //   nextFetchPolicy: fetchPolicy || 'cache-first'
        // });
        const result = await fetchQuery({
          variables,
          pollInterval,
          onCompleted() {
            startTransition(() => setLastFetchedAt(DateTime.local().toISO()));
          },
          // Skip polling if the tab isn't visible
          skipPollAttempt() {
            return tabVisibilityRef.current === 'hidden';
          },
          initialFetchPolicy: 'cache-first',
          fetchPolicy: !called && !fetchPolicy ? 'cache-and-network' : fetchPolicy || 'cache-first',
          nextFetchPolicy: 'cache-first',
          context: {
            fetchOptions: {
              signal: controller.signal
            },
            stack: error.current.stack,
            cancellable
          }
        });
        onAfterRefetch?.();
        return result;
      } catch (e) {
        if (e instanceof Error) {
          log(`[Apollo::Hooks::useLazyQueryEffect::${hookName}::refetchQuery]: Error`, e);
          onRefetchError?.(e);
        }
        console.error(e);
      }
    },
    [
      skip,
      tabVisibilityRef,
      onBeforeRefetch,
      hookName,
      loading,
      cancellable,
      called,
      fetchPolicy,
      onAfterRefetch,
      fetchQuery,
      pollInterval,
      onRefetchError
    ]
  );

  const query = useRef<typeof refetchQuery | ReturnType<typeof debounce<typeof refetchQuery>>>(
    debounceSettings ? debounce(refetchQuery, debounceSettings.wait, debounceSettings) : refetchQuery
  );

  useEffect(() => {
    if (query.current && 'cancel' in query.current) {
      query.current.cancel();
    }
    if (debounceSettings) {
      query.current = debounce(refetchQuery, debounceSettings.wait, debounceSettings);
    } else {
      query.current = refetchQuery;
    }
  }, [debounceSettings, refetchQuery]);

  useEffect(() => {
    if (skip) return;
    if (isEqual(normalizedCurrentVariables, normalizedIncomingVariables)) return;
    if (debug) {
      // eslint-disable-next-line no-console
      log(`[Apollo::Hooks::useLazyQueryEffect::${hookName}]: Variables have changed, refetching query`, {
        previous: normalizedCurrentVariables,
        incoming: normalizedIncomingVariables
      });
    }
    query.current(normalizedIncomingVariables);
  }, [debug, hookName, isEqual, normalizedCurrentVariables, normalizedIncomingVariables, query, skip]);

  useEffect(
    () => () => {
      if (query.current && 'cancel' in query.current) {
        query.current.cancel();
      }
    },
    []
  );

  // Force refresh a query if the query is polling, and the tab
  // has been in the background and has been resumed
  useEffect(() => {
    if (!pollInterval) return;
    if (!lastFetchedAt) return;
    if (tabVisibility === 'hidden') return;
    const lastFetchedMs = DateTime.local().toMillis() - DateTime.fromISO(lastFetchedAt).toMillis();

    // If query hasn't been fetched since the poll interval,
    // e.g if the pollInterval is 3 minutes, and the tab was
    // suspended for 3 minutes 10 seconds, instantly force
    // a refetch because the pollInterval exceeds the suspended
    // time:
    if (lastFetchedMs > pollInterval) {
      query.current(normalizedIncomingVariables);
    }
  }, [lastFetchedAt, normalizedIncomingVariables, pollInterval, tabVisibility]);

  return [query.current, { ...ctx, infiniteScroll, abort: abortController.current?.abort || noop }] as const;
}

interface IUseLazyRefetchOpts<Query, Variables> extends IUseLazyQueryEffectOpts<Query, Variables> {
  // Don't empty the result set when loading new data,
  // instead return previousData
  fallbackToPreviousData?: boolean;
}
/**
 * Wrapper around useLazyQueryEffect that allows you to pass in
 * the hook directly, so that you don't need to do:
 *   const [getSomething, query] = useSomethingLazyQuery();
 *   useLazyQueryEffect(getSomething, query.variables, { id: something.id })
 *
 * Instead you can do either one or these:
 *   const [getSomething, query] = useLazyRefetch(useSomethingLazyQuery, { id: something.id })
 *
 * OR you can pass the result in directly:
 *  const [getSomething, query] = useLazyRefetch(useSomethingLazyQuery(), { id: something.id });
 *
 * This hook forwards the options to useLazyQueryEffect, so you can pass
 * in the same options
 */
export function useLazyRefetch<Variables extends { [key: string]: unknown }, Query extends object>(
  hookOrResult:
    | LazyQueryResultTuple<Query, Variables>
    | ((baseOptions?: LazyQueryHookOptions<Query, Variables>) => LazyQueryResultTuple<Query, Variables>),
  variables?: Variables,
  opts?: IUseLazyRefetchOpts<Query, Variables>
) {
  const { fallbackToPreviousData = false } = opts || {};
  const [fetchQuery, query] = useLazyQueryEffect(hookOrResult, variables, opts);

  const result = useMemo(() => {
    // If fallbackToPreviousData is set to true,
    // then return previousData if data is empty
    // when the request is loading. If the request is
    // done loading, always return data so we don't have
    // stale data.
    if (fallbackToPreviousData) {
      return {
        ...query,
        data: query.loading ? query.data || query.previousData : query.data
      };
    }
    return query;
  }, [fallbackToPreviousData, query]);

  return [fetchQuery, result] as const;
}
