import * as React from 'react';
import { useCallback, useEffect, useMemo } from '@zavy360/hooks/react';
import type { SessionQuery } from '@zavy360/graphql/operations';
import isEqual from 'fast-deep-equal';
import {
  SessionProviderBannerWarnings,
  SessionStatus,
  getBannerFromSessionUpdate,
  getSessionStateFromSession
} from './utils';
import { debug } from '../utils/debug';

export interface ISessionState {
  status: SessionStatus;
  session: SessionQuery['session'];
  banner: SessionProviderBannerWarnings;
}

export const INITIAL_SESSION_STATE: ISessionState = {
  status: SessionStatus.NONE,
  session: null,
  banner: null
};

interface ISessionAction<Type> {
  type: Type;
}

interface ISessionPayloadAction<Type, Payload> extends ISessionAction<Type> {
  value: Payload;
}

export type TSessionActions =
  | ISessionAction<'RESET'>
  | ISessionPayloadAction<'SET_SESSION', SessionQuery['session']>
  | ISessionPayloadAction<'SET_STATE', SessionStatus>;

function reducer(state: ISessionState, action: TSessionActions): ISessionState {
  switch (action.type) {
    case 'RESET':
      debug('[Apollo::Session::State:RESET] Resetting session context');
      return INITIAL_SESSION_STATE;
    case 'SET_SESSION': {
      if (action.value === null) return { ...state, status: SessionStatus.INVALID, session: null };
      if (isEqual(state.session, action.value || {})) {
        debug('[Apollo::Session::State:SET_SESSION] Session identical, no update performed', {
          session: state.session,
          incoming: action.value
        });
        return state;
      }

      // Don't remove DISABLED banner if the session is disabled,
      // and the user didn't change
      const banner = getBannerFromSessionUpdate(state.session, action.value);
      const newSession: ISessionState = {
        status: getSessionStateFromSession(action.value),
        banner:
          state?.session?.practiceConnection?.guid === action.value?.practiceConnection?.guid &&
          state?.status === SessionStatus.DISABLED
            ? state.banner
            : banner,
        session: {
          ...(state.session || {}),
          ...action.value
        }
      };
      debug('[Apollo::Session::State:SET_SESSION] Incoming session:', newSession);
      return newSession;
    }
    case 'SET_STATE':
      debug('[Apollo::Session::State:SET_STATE] Status update:', action.value);
      if (action.value === SessionStatus.DISABLED) {
        return {
          ...state,
          banner: SessionProviderBannerWarnings.SwitchedUser,
          status: action.value
        };
      }
      if (action.value === SessionStatus.VALID) {
        return {
          ...state,
          status: action.value,
          banner: getBannerFromSessionUpdate(state.session, state.session)
        };
      }
      return {
        ...state,
        status: action.value
      };
    default:
      return state;
  }
}

export function useSessionState() {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_SESSION_STATE);

  useEffect(() => {
    debug('[Apollo::Session::State] Session State changed:', state);
  }, [state]);

  const setSession = useCallback((value: SessionQuery['session']) => dispatch({ type: 'SET_SESSION', value }), []);

  // NOTE: Something is weird with this specific memoization
  // causing an error with `a.valueOf` does not exist on undefined
  // which must happen when we run fast-deep-equal on these dependencies,
  // possibly when `client` is undefined.
  const clear = useCallback(async () => dispatch({ type: 'RESET' }), []);

  // If this is set to false, the user is logged out
  const setState = useCallback((value: SessionStatus) => {
    dispatch({ type: 'SET_STATE', value });
  }, []);

  const actions = useMemo(
    () => ({
      setSession,
      setState,
      clear
    }),
    [setState, setSession, clear]
  );

  return { state, actions };
}
