import { useApolloClient, type ApolloCache, type StoreObject } from '@apollo/client';
import { useFragmentWhere } from '@nerdwallet/apollo-cache-policies';
import { createContext } from '@zavy360/hooks/createContext';
import { useCallback, useEffect, useMemo } from '@zavy360/hooks/react';
import type { DocumentNode } from 'graphql';
import isEqual from 'lodash/isEqual';
import { useState } from 'react';

export interface ICreateSelectionOptions<FragmentType> {
  fragment: DocumentNode;
  fragmentName: string;
  // Uniquely identifies the node
  getKey(node: FragmentType): string;

  // Uniquely identifies the group the node should belong to,
  // in cases of grouped selections
  getGroupKey?(node: FragmentType): string;
}

/**
 * This allows you to create a selection context that
 * keeps fragments updated and synced with the Apollo cached
 * instead of keeping selected items in state.
 *
 * This allows any child components in the tree to access
 * selection state.
 *
 * Selection can optionally also be grouped by a field on the object,
 * such that you can consume `useSelectionContext(groupId)`, which scopes
 * selected items (and setSelection) to only items with that groupId. This
 * only works when `getGroupKey` is defined.
 *
 */
export function createFragmentSelection<FragmentType>(options: ICreateSelectionOptions<FragmentType>) {
  const { fragment, fragmentName, getKey, getGroupKey } = options;

  /**
   * Ensures the fragment is in the cache and available for selection,
   * preventing potential race conditions where we try to find the fragment
   * before its available in the cache (not sure how this happens, but it does)
   */
  function writeFragment(cache: ApolloCache<unknown>, item: FragmentType) {
    try {
      cache.writeFragment({
        fragment,
        fragmentName,
        id: cache.identify(item as StoreObject),
        data: item,
        broadcast: true
      });
    } catch (e) {
      console.warn('Failed to write fragment to cache', e);
    }
  }

  /**
   * Manage internal selection state
   */
  function useSelection() {
    const [keys, setKeys] = useState<string[]>([]);
    const { cache } = useApolloClient();
    const { data } = useFragmentWhere<FragmentType>(fragment, { returnPartialData: true });
    const selected = useMemo(() => data?.filter((node) => keys?.includes(getKey(node))) || [], [data, keys]);

    // Print a warning in the console if selected items aren't found in the cache
    useEffect(() => {
      const missingKeys = keys.filter((key) => !data?.some((node) => getKey(node) === key));
      if (missingKeys.length > 0) {
        console.warn(`Selected items not found in cache: ${missingKeys.join(', ')}`, data);
      }
    }, [data, keys]);

    const setSelection = useCallback(
      function setSelection(
        itemsOrIds: Array<string | FragmentType> | ((keys: string[]) => Array<string | FragmentType>)
      ) {
        setKeys((current) => {
          const items = typeof itemsOrIds === 'function' ? itemsOrIds(current) : itemsOrIds;
          for (const item of items) {
            if (typeof item === 'string') continue;
            if (current.includes(getKey(item))) continue;
            writeFragment(cache, item);
          }
          const normalizedKeys = Array.from(
            new Set(
              items.map((item) => {
                if (typeof item === 'string') return item;
                return getKey(item);
              })
            )
          );
          if (isEqual(normalizedKeys, current)) return current;
          return normalizedKeys;
        });
      },
      [cache]
    );

    return useMemo(() => ({ selected, setSelection }), [selected, setSelection]);
  }

  /**
   * Create a context provider and consumer from the hook above
   */
  const { Provider: SelectionProvider, useContext } = createContext(useSelection, {
    selected: [],
    setSelection: () => null
  });

  /**
   * Wrap the useContext to support scoping selection to selection
   * groups
   */
  function useSelectionContext(groupId?: string) {
    const { selected, setSelection } = useContext();

    const selectedInGroup = useMemo(
      () => (!groupId ? selected : selected.filter((item) => getGroupKey(item) === groupId)),
      [groupId, selected]
    );
    const setSelectionInGroup = useCallback(
      function setSelectionInGroup(
        itemsOrIdsOrFn: Array<string | FragmentType> | ((items: string[]) => Array<string | FragmentType>)
      ) {
        setSelection((current) => {
          const items = typeof itemsOrIdsOrFn === 'function' ? itemsOrIdsOrFn(current) : itemsOrIdsOrFn;
          const selectedOutsideGroup = selected.filter((item) => getGroupKey(item) !== groupId);
          return [...selectedOutsideGroup, ...items];
        });
      },
      [groupId, selected, setSelection]
    );

    return useMemo(() => {
      if (groupId) {
        return { selected: selectedInGroup, setSelection: setSelectionInGroup };
      }

      return { selected, setSelection };
    }, [groupId, selected, selectedInGroup, setSelection, setSelectionInGroup]);
  }

  return { SelectionProvider: SelectionProvider, useSelectionContext };
}
