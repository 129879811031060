import FullScreenSpinner from '@zavy360/components/Spinners/FullScreenSpinner';
import * as React from 'react';
import { useMemo } from '@zavy360/hooks/react';
import { SessionContext } from './SessionProvider';
import { useSessionStateContext } from './state';
import { SessionStatus, isInitialized } from './state/utils';

export default function useSession() {
  const ctx = React.useContext(SessionContext);
  const { state } = useSessionStateContext();
  const { status } = state;
  return useMemo(
    () => ({
      ...ctx,
      ...state,

      // Backwards compatible
      initialized: isInitialized(status),
      loading: status === SessionStatus.INITIALIZING,
      called: status !== SessionStatus.NONE
    }),
    [ctx, status, state]
  );
}

export function hideUntilSessionReady<P>(Component: React.ComponentType<P>): React.ComponentType<P> {
  return function WrappedComponent(props: P) {
    const session = useSession();

    if (!session?.session?.practice?.id) {
      return <FullScreenSpinner />;
    }

    return <Component {...props} />;
  };
}
