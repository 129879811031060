import { useCallback } from '@zavy360/hooks/react';
import { useEnvironment } from '../../crud/useApplicationEnvironment';
import { SessionProviderBannerWarnings } from '../state/utils';
import useSession from '../useSession';
import AccessRestrictionWarningBanner from './AccessRestrictionWarningBanner';
import OutdatedDeploymentWarningBanner from './OutdatedDeploymentWarningBanner';
import { SwitchedSessionOverlay } from './SwitchedSessionOverlay';

/**
 * This component is necessary to render banners from SessionProvider
 * beneath the ZavyIntlProvider which provides the Antd prefixCls
 * When SessionProvider renders Antd components, it wont have access to the
 * theme settings and CSS, and the styling will occasionally be missing depending
 */
export default function SessionBanner() {
  const { banner, clearAccessRestriction, loading, session } = useSession();
  const { outdated } = useEnvironment();

  const onPageReload = useCallback(() => {
    window.location.reload();
  }, []);

  if (outdated) {
    return <OutdatedDeploymentWarningBanner loading={loading} onAction={onPageReload} />;
  }

  switch (banner) {
    case SessionProviderBannerWarnings.AccessRestriction:
      return (
        <AccessRestrictionWarningBanner
          loading={loading}
          onAction={clearAccessRestriction}
          accessRestriction={session?.accessRestriction}
        />
      );
    case SessionProviderBannerWarnings.OutdatedDeployment:
      return <OutdatedDeploymentWarningBanner loading={loading} onAction={onPageReload} />;
    case SessionProviderBannerWarnings.SwitchedUser:
      return <SwitchedSessionOverlay upc={session?.practiceConnection} />;
    default:
      return null;
  }
}
