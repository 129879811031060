import { useMemo } from '@zavy360/hooks/react';

type InferredNode<T> = T extends { edges?: { node?: infer U }[] } ? U : never;

// Extract nodes from edges, since we do this pretty much
// all the time
export function useNodes<T extends { edges?: { node?: object }[] }, NodeType extends InferredNode<T>>(
  results?: T
): NodeType[] {
  return useMemo(() => {
    return (
      results?.edges
        ?.filter(Boolean)
        ?.map(({ node }) => node)
        ?.filter(Boolean) || []
    );
  }, [results]) as NodeType[];
}
