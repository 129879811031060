import type { ApolloCache, MutationHookOptions, MutationTuple } from '@apollo/client';
import { useCallback } from '@zavy360/hooks/react';
import React from 'react';
import merge from 'lodash/merge';
import type { FieldError } from '@zavy360/graphql/schema';
import { useAppSignalContext as useAppSignal } from '@zavy360/components/AppSignal/AppSignalContext';

// Default mutation response structure used for type inference
export interface IZavyMutationPayloadStructure {
  fieldErrors?: FieldError[] | null;
  errors?: string[] | null;
}

export interface IUseMutationOpts<Variables, Payload> {
  // Set a name for debugging purposes
  name?: string;
  // Print debug info for this mutation
  debug?: boolean;

  optimisticResponse?(variables: Variables, cache: ApolloCache<object>): DeepExact<Payload> | undefined;

  // Override variables passed to the mutation with these
  overrideVariables?: DeepPartial<Variables>;

  // Set default variables for the mutation, but allow them to be overridden
  defaultVariables?: DeepPartial<Variables>;
}

export function useMutationCallback<
  // biome-ignore lint/suspicious/noExplicitAny: any is only used for type inference and is fine here
  MutationHook extends (opts?: any) => MutationTuple<any, any, any, any>,
  // biome-ignore lint/suspicious/noExplicitAny: any is only used for type inference and is fine here
  MutationHookResult = MutationHook extends (opts?: any) => infer P ? P : never,
  // biome-ignore lint/suspicious/noExplicitAny: any is only used for type inference and is fine here
  MutationVariables = MutationHookResult extends MutationTuple<any, infer V, any, any> ? V : never,
  // biome-ignore lint/suspicious/noExplicitAny: any is only used for type inference and is fine here
  MutationPayload = MutationHookResult extends MutationTuple<infer V, any, any, any> ? V : never,
  // This is the data under createSomething / updateSomething, e.g
  // the actual payload containing errors, field errors, and anything else
  MutationPayloadData = MutationPayload extends { [key: string]: infer V } ? (V extends string ? never : V) : object,
  TypedMutationPayload extends
    Partial<IZavyMutationPayloadStructure> = MutationPayloadData extends Partial<IZavyMutationPayloadStructure>
    ? MutationPayloadData
    : IZavyMutationPayloadStructure,
  // Infer overridden variables and omit them from the mutation variables
  UseMutationOptions extends Omit<
    MutationHookOptions<MutationPayload, MutationVariables>,
    'optimisticResponse' | 'variables'
  > &
    IUseMutationOpts<MutationVariables, MutationPayload> = Omit<
    MutationHookOptions<MutationPayload, MutationVariables>,
    'optimisticResponse' | 'variables'
  > &
    IUseMutationOpts<MutationVariables, MutationPayload>

  // Infer default variables and make them optional
>(useMutationHook: MutationHook, options?: UseMutationOptions) {
  const {
    debug,
    optimisticResponse,
    overrideVariables = {},
    defaultVariables = {},
    ...hookOptions
  } = {
    debug: false,
    optimisticResponse: () => undefined,
    overrideVariables: {},
    defaultVariables: {},
    ...options
  };
  const { appSignal } = useAppSignal();
  const [mutate, { client }] = useMutationHook();
  const [submitting, setSubmitting] = React.useState(false);

  const log = useCallback(
    // biome-ignore lint/suspicious/noExplicitAny: This is the actual type for console.debug
    (...args: any[]) => {
      if (!debug) return;
      const name = options?.name || useMutationHook?.name;
      console.log(`[useMutationCallback][${name}]:`, ...args);
    },
    [debug, options?.name, useMutationHook?.name]
  );

  const onMutate = useCallback(
    async (vars: MutationVariables): Promise<TypedMutationPayload> => {
      try {
        const variables: MutationVariables = merge({}, defaultVariables, vars, overrideVariables);
        log('Submitting mutation with:', {
          variables,
          optimisticResponse: optimisticResponse?.(variables, client.cache)
        });
        setSubmitting(true);
        const result = await mutate({
          variables,
          optimisticResponse: optimisticResponse?.(variables, client.cache),
          ...hookOptions
        });

        // Figure out the name of the mutation,
        // it'll be the only key in the result
        log('Received response:', result?.data);
        const mutationName = Object.keys(result?.data ?? {})?.[0];
        log('Detected mutation name:', mutationName);
        if (mutationName && result.data?.[mutationName]) {
          const { fieldErrors, errors, ...successPartial } = result.data[mutationName] as TypedMutationPayload;
          const errorPartial = { errors, fieldErrors };
          log('Parsed partials from payload: ', { errorPartial, successPartial });

          const response = {
            fieldErrors,
            errors,
            ...successPartial
          } as TypedMutationPayload;

          log('Returning response:', response);
          return response;
        }
        log('Failed to parse mutation name from response, returning void');
        return { errors: result?.errors || ['Something went wrong'] } as TypedMutationPayload;
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          appSignal.sendError(error);
          return { errors: ['Something went wrong'] } as TypedMutationPayload;
        }
      } finally {
        setSubmitting(false);
      }
    },
    [defaultVariables, appSignal, overrideVariables, log, optimisticResponse, mutate, hookOptions, client.cache]
  );

  return [onMutate, submitting] as const;
}

export const noopMutationCallback = [async () => null, false] as [() => Promise<null>, false];
