import { useCallback, useEffect, useMemo } from '@zavy360/hooks/react';
import type { SessionQuery } from '@zavy360/graphql/operations';
import { useSessionStateContext } from '../state';
import { useSessionLazyQuery } from '../../hooks';
import { SessionStatus, getSessionStateFromSession } from '../state/utils';
import { debug } from '../utils/debug';

export default function useCurrentSession() {
  const { actions, state } = useSessionStateContext();
  const { session } = state;
  const { setSession, setState } = actions;

  const onCompleted = useCallback(
    (payload: SessionQuery) => {
      // Write the session ID to localStorage
      // so we can tag requests with it
      localStorage.setItem('zSessionId', payload?.session?.id);

      // If we just received a new session, and had no
      // previous session, refetch all queries
      // console.debug(
      //   '[Apollo::Session::Hooks::CurrentSession] Setting session status ',
      //   getSessionStateFromSession(payload?.session)
      // );
      setState(getSessionStateFromSession(payload?.session));
    },
    [setState]
  );

  // Load the session on demand, network only at first
  const [getSession, result] = useSessionLazyQuery({
    errorPolicy: 'all',
    // Removed the cache-only query and forcing session to always load from
    // cache and then perform a network request regardless to make sure
    // data hasn't expired. Specifically for Session, cache-and-network is
    // the appropriate policy.
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted
  });
  const { called, data } = result;

  const fetchSession = useCallback(async () => {
    if (!data?.session) {
      // console.debug(
      //   '[Apollo::Session::Hooks::CurrentSession] No session found, fetching and setting status ',
      //   SessionStatus.INITIALIZING
      // );
      setState(SessionStatus.INITIALIZING);
    }
    return getSession({ fetchPolicy: 'network-only', nextFetchPolicy: 'network-only' });
  }, [getSession, data?.session, setState]);

  useEffect(() => {
    if (!session) {
      debug('[Apollo::Session::Hooks::CurrentSession] Retrieving session');
      fetchSession();
    }
  }, [fetchSession, session]);

  const reload = useCallback(() => {
    setSession(null);
    debug('[Apollo::Session::Hooks::CurrentSession] Reloading session');
    return getSession();
  }, [getSession, setSession]);

  return useMemo(
    () => ({
      session: result?.data?.session,
      called,
      reload
    }),
    [reload, called, result?.data?.session]
  );
}
