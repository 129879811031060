import { DateTime } from 'luxon';
import type { SessionFragment, SessionQuery } from '@zavy360/graphql/operations';

export enum SessionProviderBannerWarnings {
  AccessRestriction = 'access_restriction',
  OutdatedDeployment = 'outdated_deployment',
  SwitchedUser = 'switched_user',
  IdleTab = 'idle_tab'
}

export enum SessionStatus {
  // Not initialized yet
  NONE = 'NO_SESSION',

  // Loading initial session
  INITIALIZING = 'INITIALIZING',
  // Session expires soon
  EXPIRING_SOON = 'EXPIRING_SOON',
  // User has an access restriction applied
  ACCESS_RESTRICTION = 'VALID_WITH_ACCESS_RESTRICTION',

  // Session is valid, but user has switched user,
  // and this session must be disabled until switched back
  DISABLED = 'DISABLED',

  // User session is not valid, logged out
  INVALID = 'INVALID',
  // Valid user session
  VALID = 'VALID'
}

export const SESSION_MINUTES_LEFT_BEFORE_REFRESH = 60;

export function getSessionStateFromSession(session?: SessionQuery['session']) {
  if (!session) return SessionStatus.INVALID;
  if (session?.accessRestriction?.id) return SessionStatus.ACCESS_RESTRICTION;
  const expiry = DateTime.fromISO(session.expiresAt).toUTC();
  const earliestRefresh = DateTime.local().plus({ minutes: SESSION_MINUTES_LEFT_BEFORE_REFRESH }).toUTC();

  if (expiry < DateTime.local().toUTC()) return SessionStatus.INVALID;
  if (expiry < earliestRefresh) return SessionStatus.EXPIRING_SOON;
  return SessionStatus.VALID;
}

export function isValidSession(state: SessionStatus) {
  return [SessionStatus.ACCESS_RESTRICTION, SessionStatus.VALID, SessionStatus.EXPIRING_SOON].includes(state);
}

export function isInitialized(state: SessionStatus) {
  return ![SessionStatus.NONE, SessionStatus.INITIALIZING].includes(state);
}

export function getBannerFromSessionUpdate(old: SessionFragment | null, session: Partial<SessionFragment> | null) {
  if (session?.accessRestriction?.id) {
    return SessionProviderBannerWarnings.AccessRestriction;
  }
  const currentSessionCommit = old?.env?.commit;
  const incomingSessionCommit = session?.env?.commit;

  if (currentSessionCommit && incomingSessionCommit && currentSessionCommit !== incomingSessionCommit) {
    return SessionProviderBannerWarnings.OutdatedDeployment;
  }

  return null;
}
