import * as React from 'react';
import { Affix, Button, Col, Row, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import type { AccessRestriction } from '@zavy360/graphql/schema';
import { createStyles } from '@zavy360/config/themes';
import startCase from 'lodash/startCase';

interface IAccessRestrictionWarningBannerProps {
  accessRestriction: Pick<AccessRestriction, 'name' | 'id' | 'authenticationType'>;
  loading?: boolean;
  onAction?(): void;
}

const useStyles = createStyles({
  loginHeader: {
    backgroundColor: '#ffffffcc',
    backdropFilter: 'blur(10px)',
    padding: 8,
    width: '100%',
    borderTop: '1px solid #eee',
    position: 'absolute',
    zIndex: 1001
  }
});

export default function AccessRestrictionWarningBanner(props: IAccessRestrictionWarningBannerProps) {
  const styles = useStyles();
  const { onAction, accessRestriction, loading } = props;
  return (
    <Affix offsetTop={0}>
      <Row justify='space-between' className={styles.loginHeader}>
        <Col>
          <span>
            <Tooltip title='This user has restricted access context with limited permissions'>
              <UserOutlined />{' '}
            </Tooltip>

            {[
              `You are logged in as ${startCase(accessRestriction?.name)} `,
              accessRestriction?.authenticationType === 'auto'
                ? 'This one time link will expire 24 hours after accessing it'
                : undefined
            ]
              .filter(Boolean)
              .join('. ')}
          </span>
        </Col>
        <Col>
          <Button size='small' onClick={onAction} disabled={loading} loading={loading}>
            Sign out
          </Button>
        </Col>
      </Row>
    </Affix>
  );
}
