import { type OnDataOptions, useApolloClient } from '@apollo/client';
import { useAvatarUpdated } from '@zavy360/graphql/crud/useUPC';
import { useCallback } from '@zavy360/hooks/react';
import { DateTime } from 'luxon';
import {
  SessionDocument,
  useAccountPlanUpdatedSubscription,
  useSessionStatusLazyQuery,
  useSessionUpdatedSubscription
} from '../../hooks';
import type { SessionFragment, SessionQuery, SessionStatusQuery, SessionUpdatedSubscription } from '../../operations';
import { useSessionStateContext } from '../state';
import { SessionStatus, getSessionStateFromSession } from '../state/utils';
import { debug } from '../utils/debug';

export function useSessionUpdate() {
  const { actions, state } = useSessionStateContext();
  const { session } = state;
  const { setState } = actions;

  const { cache } = useApolloClient();
  const updateSessionQuery = useCallback(
    (newSession: Partial<SessionFragment>) => {
      debug('[Apollo::Session::Hooks::SessionPolling] Writing session query to cache', {
        session,
        newSession
      });
      cache.updateQuery<SessionQuery>(
        {
          query: SessionDocument,
          overwrite: true,
          broadcast: true
        },
        (prev) => {
          if (!newSession) {
            debug('[Apollo::Session::Hooks::SessionPolling] Received empty session, writing null session to store');
            return {
              ...prev,
              session: null
            };
          }
          // console.debug('Merging with', {
          //   prev: prev?.session,
          //   newSession,
          //   merged: {
          //     ...prev?.session,
          //     ...newSession
          //   }
          // });

          return {
            ...prev,
            session: {
              ...prev?.session,
              ...newSession
            }
          };
        }
      );
    },
    [cache, session]
  );

  const onCompleted = useCallback(
    (payload: Partial<SessionStatusQuery>) => {
      if (!session) return debug('[Apollo::Session::Hooks::SessionPolling] No current session, cache wont be updated');
      // If the new session changes the user ID, without changing access restriction,
      // and this tab isn't active, then display a warning
      // banner but don't update the session
      const isLoggedIn = !!payload?.sessionStatus?.practiceConnection?.guid;
      const wasLoggedIn = !!session?.practiceConnection?.guid;
      const isSameAccessRestriction = session?.accessRestriction?.id === payload?.sessionStatus?.accessRestriction?.id;
      const isSameUPC = payload?.sessionStatus?.practiceConnection?.guid === session?.practiceConnection?.guid;
      // console.debug('SESSION UPDATE', {
      //   isLoggedIn,
      //   wasLoggedIn,
      //   isSameAccessRestriction,
      //   isSameUPC,
      //   prev: session,
      //   new: payload
      // });

      const { isValid, tokenExpiry } = payload?.sessionStatus || {
        isValid: false,
        tokenExpiry: DateTime.local().minus({ seconds: 1 }).toISO()
      };

      // If the token isn't valid, or the token expiry is in the past,
      // invalidate the session and let the automatic logout hook handle
      // logging out
      if (!isValid) {
        debug(
          '[Apollo::Session::Hooks::SessionPolling] Received a session with an expiry date in the past, setting status ',
          SessionStatus.INVALID
        );
        setState(SessionStatus.INVALID);
        return;
      }

      let update: Partial<SessionQuery['session']> = {};

      // If session status received an access restriction,
      // add or remove it from session cache
      if (payload?.sessionStatus?.accessRestriction?.id !== session?.accessRestriction?.id) {
        debug(
          '[Apollo::Session::Hooks::SessionPolling] Received access restriction, updating query with access restriction'
        );
        update = { ...update, accessRestriction: payload?.sessionStatus?.accessRestriction };
      } else if (payload?.sessionStatus?.accessRestriction?.id) {
        debug('[Apollo::Session::Hooks::SessionPolling] Received access restriction already in current session', {
          sessionStatus: payload,
          session
        });
      }

      if (tokenExpiry) {
        debug('[Apollo::Session::Hooks::SessionPolling] Writing new token expiry to session', tokenExpiry);
        update = { ...update, expiresAt: tokenExpiry };
      }

      // This was previously causing race conditions when multiple updates
      // were received very quickly, e.g consecutively
      if (Object.keys(update).length) {
        updateSessionQuery(update);
      }

      if (isLoggedIn && wasLoggedIn && isSameAccessRestriction) {
        if (!isSameUPC) {
          actions.setState(SessionStatus.DISABLED);
          debug(
            '[Apollo::Session::Hooks::SessionPolling] Disabling this tab until session is updated with correct UPC'
          );
        } else if (state.status === SessionStatus.DISABLED) {
          debug('[Apollo::Session::Hooks::SessionPolling] Enabling this tab again', tokenExpiry);
          actions.setState(getSessionStateFromSession(session));
        }
      }
    },
    [actions, session, setState, state.status, updateSessionQuery]
  );

  const [, { refetch: checkSessionStatus }] = useSessionStatusLazyQuery();

  // Check SessionStatus when a SessionUpdated subscription is received
  const onData = useCallback(
    ({ data: subscription }: OnDataOptions<SessionUpdatedSubscription>) => {
      debug('[Apollo::Session::Hooks::SessionPolling] SessionUpdate Subscription Received', {
        subscription,
        sessionId: session?.id
      });
      if (subscription?.data?.sessionUpdated?.session?.id !== session?.id) return;
      debug('[Apollo::Session::Hooks::SessionPolling] Checking session status...');
      checkSessionStatus().then(({ data }) => onCompleted(data));
    },
    [session?.id, checkSessionStatus, onCompleted]
  );

  useAvatarUpdated(session);

  useSessionUpdatedSubscription({
    onData,
    fetchPolicy: 'network-only',
    skip: !session?.id,
    onError: console.error,
    onComplete: console.log
  });
  useAccountPlanUpdatedSubscription({
    skip: !session?.practice,
    onData: ({ data: { data }, client }) => {
      // Manually update session query here, because for some reason
      // receiving an update to the AccountPlan doesn't seem to update it
      // in the cache for the session query otherwise
      if (!data?.accountPlanUpdated?.accountPlan?.id) return;
      client.cache.updateQuery<SessionQuery>({ query: SessionDocument, broadcast: true }, (prev) => ({
        ...prev,
        session: {
          __typename: 'Session',
          ...prev?.session,
          practice: {
            ...prev?.session.practice,
            accountPlan: data?.accountPlanUpdated?.accountPlan
          }
        }
      }));
    }
  });

  return null;
}
