import { useCallback, useEffect, useMemo } from '@zavy360/hooks/react';
import useActivity from '@zavy360/hooks/device/useActivity';
import { useSessionStateContext } from '../state';
import { useRefreshAuthTokenMutation } from '../../hooks';
import { SessionStatus } from '../state/utils';
import { debug } from '../utils/debug';
import { useRef } from 'react';
import { DateTime } from 'luxon';

interface IUseTokenRefreshOpts {
  onRefresh?(): void;
}
export function useTokenRefresh(opts: IUseTokenRefreshOpts) {
  const { onRefresh } = opts;
  const { state, actions } = useSessionStateContext();
  const { setState } = actions;
  const { session, status } = state;
  const { isIdle } = useActivity({
    secondsUntilIdle: 5 * 60, // 5 Minutes
    promptUser: false // Don't prompt the user on whether or not they're idle
  });
  const [refreshToken, { loading }] = useRefreshAuthTokenMutation({
    onCompleted: (payload) => {
      // If we fail to refresh the token, update session status
      // to no longer be EXPIRING_SOON, to prevent further fetches
      if (payload?.refreshToken?.success === false) {
        debug('[Apollo::Session::Hooks::TokenRefresh]: Failed to refresh token');
        setState(SessionStatus.INVALID);
      }
    }
  });

  const shouldRefreshAuthToken = useMemo(() => {
    if (!session) return false;
    if (status !== SessionStatus.EXPIRING_SOON) return false;

    if (isIdle) {
      debug('[Apollo::Session::Hooks::TokenRefresh]: Session wont be refreshed while idle');
    }
    return !isIdle;
  }, [isIdle, session, status]);

  const onRefreshToken = useCallback(async () => {
    await refreshToken();
    onRefresh?.();
  }, [onRefresh, refreshToken]);

  // Set up a timer to refresh the token 1 hour before it expires
  const timer = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!session?.expiresAt) return;
    if (loading) return;
    if (timer.current) clearTimeout(timer.current);
    const expiresIn = DateTime.fromISO(session?.expiresAt).toUTC().toMillis() - DateTime.local().toUTC().toMillis();

    if (expiresIn < 1) return; // Dont set timers in the past
    // Set timer to an hour before the token expires
    console.debug(
      '[Apollo::Session::Hooks::TokenRefresh]: Setting timer to refresh token',
      DateTime.fromISO(session?.expiresAt, { zone: 'Australia/Sydney' }).minus({ milliseconds: 3600000 }).toISO(),
      {
        expiresIn,
        expiresAt: session?.expiresAt
      }
    );
    timer.current = setTimeout(onRefreshToken, expiresIn - 3600000 || 0);
  }, [loading, onRefreshToken, session?.expiresAt]);

  useEffect(() => {
    if (loading) return;
    if (!shouldRefreshAuthToken) return;
    onRefreshToken();
  }, [onRefreshToken, loading, shouldRefreshAuthToken]);
}
