import * as React from 'react';
import noop from 'lodash/noop';
import { useSessionState, INITIAL_SESSION_STATE } from './useSessionState';

export const SessionStateContext = React.createContext<ReturnType<typeof useSessionState>>({
  state: INITIAL_SESSION_STATE,
  actions: {
    setState: noop,
    setSession: noop,
    clear: async () => {}
  }
});

export function SessionStateProvider(props: React.PropsWithChildren<object>) {
  const { children } = props;
  const sessionState = useSessionState();
  return <SessionStateContext.Provider value={sessionState}>{children}</SessionStateContext.Provider>;
}

export function useSessionStateContext() {
  return React.useContext(SessionStateContext);
}
