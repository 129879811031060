import { noopMutationCallback, useLazyRefetch, useMutationCallback } from '@zavy360/graphql/client/hooks';
import {
  UpcDetailedDocument,
  useCopyPatientToPracticeMutation,
  useTransitionUserStateMutation,
  useUpcDetailedLazyQuery,
  useUpdateActiveProfileMutation,
  useUpdatePatientMutation
} from '@zavy360/graphql/hooks';
import type {
  UpcDetailedQuery,
  UpcDetailedQueryVariables,
  UserPracticeConnectionDetailedFragment
} from '@zavy360/graphql/operations';
import { PatientUpdateInput, UserPracticeConnectionStateEvent } from '@zavy360/graphql/schema';
import { useAsyncCallback, useCallback, useMemo } from '@zavy360/hooks/react';
import isEmpty from 'lodash/isEmpty';

import { type QueryHookOptions, useApolloClient } from '@apollo/client';
import createCRUDContext, { type TMutationResponse, uninitializedHandler } from '../factory';
import useUPCSubscription from './useUPCSubscription';

export function useUPC(opts: UpcDetailedQueryVariables & { fetchPolicy?: QueryHookOptions['fetchPolicy'] }) {
  const { fetchPolicy = 'cache-first', ...variables } = opts;
  const { cache } = useApolloClient();

  const onCompleted = useCallback(
    (data: UpcDetailedQuery) => {
      // If querying by ID, then write slug and GUID equivalents
      // to the cache, and if querying by slug or GUID, write ID
      // equivalent to the cache — this prevents duplicate calls
      // to fetch UPCDetailed when only the identifier has changed
      if (!data?.userPracticeConnection) return;
      for (const key of ['id', 'slug', 'guid']) {
        // Dont write to cache if the key is the currently queried one:
        cache?.writeQuery<UpcDetailedQuery, UpcDetailedQueryVariables>({
          query: UpcDetailedDocument,
          variables: {
            [key]: data?.userPracticeConnection?.[key]
          },
          data
        });
      }
    },
    [cache]
  );

  const [getUPC, query] = useLazyRefetch(useUpcDetailedLazyQuery, variables, {
    skip: isEmpty(variables),
    name: 'useUPC',
    fetchPolicy,
    onCompleted
  });
  const { data } = query;

  const upc = useMemo(() => data?.userPracticeConnection, [data?.userPracticeConnection]);

  useUPCSubscription({ guid: data?.userPracticeConnection?.guid });

  const setActiveProfile = useMutationCallback(useUpdateActiveProfileMutation, {
    defaultVariables: {
      upc: upc?.guid
    }
  });
  const copyToPractice = useMutationCallback(useCopyPatientToPracticeMutation);
  const update = useMutationCallback(useUpdatePatientMutation);
  const transitionState = useMutationCallback(useTransitionUserStateMutation);
  const [transition] = transitionState;
  const archive = useAsyncCallback(
    async function ArchiveConnection(): Promise<TMutationResponse<{ upc: UserPracticeConnectionDetailedFragment }>> {
      return transition({
        id: upc?.id,
        event: UserPracticeConnectionStateEvent.Archive
      });
    },
    [transition, upc?.id]
  );

  const hasUPC = useMemo(() => {
    return (variables?.id && variables?.id === upc?.id) || (variables?.guid && variables?.guid === upc?.guid);
  }, [upc?.id, upc?.guid, variables?.id, variables?.guid]);

  const value = useMemo(
    () => ({
      loading: query?.loading && !hasUPC,
      upc,
      copyToPractice,
      setActiveProfile,
      update,
      archive,
      fetch: getUPC,
      variables: query.variables
    }),
    [query?.loading, query.variables, hasUPC, upc, copyToPractice, setActiveProfile, update, archive, getUPC]
  );

  return value;
}

const { Context, Provider, useContext } = createCRUDContext(useUPC, {
  copyToPractice: noopMutationCallback,
  setActiveProfile: noopMutationCallback,
  update: noopMutationCallback,
  archive: noopMutationCallback,
  fetch: uninitializedHandler,
  loading: false,
  variables: {},
  upc: null
});

export { Context, Provider as UPCProvider, useContext as useUPCContext };
