import { useApolloClient } from '@apollo/client/react';
import { useCallback, useEffect } from '@zavy360/hooks/react';
import { message } from 'antd';
import { DateTime, Duration } from 'luxon';

const MAX_DURATION_BEFORE_QUERY_RELOAD = Duration.fromObject({
  minutes: 5
});

export function useAutomaticQueryReloading() {
  const { reFetchObservableQueries } = useApolloClient();

  const reloadQueries = useCallback(() => {
    reFetchObservableQueries();
    message.success('Connection restored');
  }, [reFetchObservableQueries]);

  useEffect(() => {
    let lastTime = DateTime.local();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // If the tab has been suspended for more than 5 minutes,
        // show the idle banner and prompt the user to refresh
        // observable queries
        if (DateTime.local() > lastTime.plus(MAX_DURATION_BEFORE_QUERY_RELOAD)) {
          reloadQueries();
        }
      }
      lastTime = DateTime.local();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [reloadQueries]);
}
