import { AvatarFragmentDoc, useAvatarUpdatedSubscription } from '@zavy360/graphql/hooks';
import type { AvatarFragment, SessionFragment } from '@zavy360/graphql/operations';

/**
 * Subscribe to Avatar updates on any UPC, and
 * update the cache with the new Avatar.
 *
 * This subscription should be subscribed to *once*
 * on the top level of the app
 */
export function useAvatarUpdated(session?: SessionFragment | null) {
  // Subscribe to Avatar changes if this is an editable UPC
  useAvatarUpdatedSubscription({
    skip: !session?.id,
    onData({ data, client }) {
      if (!data?.data?.avatarUpdated?.avatar?.id) return;
      client.writeFragment<AvatarFragment>({
        fragment: AvatarFragmentDoc,
        data: data?.data?.avatarUpdated?.avatar
      });
    }
  });
}
