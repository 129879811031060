import * as React from 'react';
import { Alert, Button } from 'antd';

interface IOutdatedDeploymentWarningBannerProps {
  loading?: boolean;
  onAction?(): void;
}

export default function OutdatedDeploymentWarningBanner(props: IOutdatedDeploymentWarningBannerProps) {
  const { onAction, loading } = props;
  return (
    <Alert
      banner
      type='info'
      message='A new version of Zavy360 has been deployed. Reload this page to update.'
      action={
        <Button size='small' shape='round' onClick={onAction} disabled={loading} loading={loading}>
          Reload
        </Button>
      }
    />
  );
}
