import { useRef } from 'react';
import { useCallback, useEffect } from '@zavy360/hooks/react';
import { useApolloClient } from '@apollo/client';
import { message } from 'antd';
import { clear } from 'antd-mobile/es/components/dialog/clear';
import { abortController } from '@zavy360/graphql/client/utils';
import { useLogoutMutation } from '@zavy360/graphql/hooks';
import { DateTime } from 'luxon';
import { useSessionStateContext } from '../state';
import { SessionStatus, isValidSession } from '../state/utils';
import { debug } from '../utils/debug';

interface IExpiryTimer {
  timerId: ReturnType<typeof setTimeout>;
  timestamp: number;
}

export function useAutomaticLogout() {
  const { state } = useSessionStateContext();
  const { status, session } = state;
  const logoutTimer = useRef<IExpiryTimer | null>(null);

  const client = useApolloClient();
  const [logoutMutation, { loading }] = useLogoutMutation();

  // NOTE: Something is weird with this specific memoization
  // causing an error with `a.valueOf` does not exist on undefined
  // which must happen when we run fast-deep-equal on these dependencies,
  // possibly when `client` is undefined.
  const logout = useCallback(async () => {
    if (!isValidSession(status)) {
      debug('[Apollo::Session::Hooks::AutomaticLogout]: User is not logged in, and thus wont be logged out');
      return;
    }
    message.warning('Session expired, please log in again');
    await logoutMutation();

    // Cancel all pending queries
    abortController.abort();
    clear();
    client?.resetStore();
    // window.location.reload();
    window.location.href = '/login';
  }, [client, logoutMutation, status]);

  useEffect(() => {
    if (!loading && status === SessionStatus.INVALID) {
      debug('[Apollo::Session::Hooks::AutomaticLogout]: Received session status INVALID, attempting automatic logout');
      logout();
    }
  }, [status, loading, logout]);

  useEffect(() => {
    if (!session?.expiresAt) return;
    const expiresAt = DateTime.fromISO(session.expiresAt).toUTC();

    // If there's a timer already running, no need to do anything
    if (logoutTimer.current?.timerId && logoutTimer.current?.timestamp === expiresAt.toMillis()) return;

    // If there's a timer running that's going to be replaced,
    // cancel that timer
    if (logoutTimer.current?.timerId) clearTimeout(logoutTimer.current.timerId);

    const timeUntilLogout = expiresAt.toMillis() - DateTime.local().toUTC().toMillis();

    // Set a timer to log the user out when the token expires
    logoutTimer.current = {
      timerId: setTimeout(() => {
        // Don't log out if the user is already logged out or
        // if the session has been refreshed and is now valid
        if (!session.expiresAt || DateTime.fromISO(session.expiresAt) > DateTime.local()) return;
        logout();
      }, timeUntilLogout),
      timestamp: expiresAt.toMillis()
    };
  }, [session?.expiresAt, logout]);

  return logout;
}
