import type { FieldMergeFunction } from '@apollo/client/cache';

/**
 * Decodes fields from the GraphQL API into a format that is more convenient for
 * the frontend to use but allows the backend to transmit encoded data
 *
 * @param fields The fields to decode
 * @param decoder The decoder to use (default: base64)
 */
export function createFieldDecoders<ObjectType extends { [key: string]: unknown }>(
  fields: (keyof ObjectType)[],
  decoder: typeof atob = atob
) {
  return fields.reduce((acc, key: string) => {
    acc[key] = {
      merge: ((existing, incoming) => {
        try {
          if (!incoming) {
            return null;
          }
          if (!(typeof incoming === 'string')) {
            return incoming;
          }
          if (!/^encoded:/.test(incoming as string)) {
            return incoming;
          }
          return decoder(incoming.replace('encoded:', '') as string);
        } catch (e) {
          console.error('Failed to decode', e, { incoming: incoming?.toString().replace(/^encoded:/, ''), existing });
          return existing;
        }
      }) as FieldMergeFunction<ObjectType[typeof key]>
    };
    return acc;
  }, {});
}
