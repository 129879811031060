import * as React from 'react';
import { useCallback, useEffect, useMemo } from '@zavy360/hooks/react';
import { type ApolloQueryResult, useApolloClient } from '@apollo/client';
import type { SessionQuery } from '@zavy360/graphql/operations';
import { useNavigate } from 'react-router-dom';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import { useClearAccessRestrictionMutation } from '../hooks';
import { useSessionUpdate } from './hooks/useSessionUpdate';
import { useTokenRefresh } from './hooks/useTokenRefresh';
import { useAutomaticLogout } from './hooks/useAutomaticLogout';
import useCurrentSession from './hooks/useCurrentSession';
import { useSessionStateContext } from './state';
import { debug } from './utils/debug';
import { SessionStatus } from './state/utils';
import { useAutomaticQueryReloading } from './hooks/useAutomaticQueryReloading';

interface ISessionContext {
  called: boolean;
  clearAccessRestriction(): void;
  logout(): void;
  reload(): Promise<ApolloQueryResult<SessionQuery>>;
}
export const SessionContext = React.createContext<ISessionContext>({
  called: false,
  reload: () => null,
  logout: noop,
  clearAccessRestriction: noop
});

export default function SessionProvider({ children }: { children: React.ReactNode }) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const logout = useAutomaticLogout();
  const { state, actions } = useSessionStateContext();
  const { setSession, setState } = actions;
  const { session: cachedSession, status } = state;
  const query = useCurrentSession();
  const { session, reload, called } = query;

  useSessionUpdate();
  useTokenRefresh({
    onRefresh: reload
  });
  useAutomaticQueryReloading();
  const [clearRestriction] = useClearAccessRestrictionMutation();

  const onClearRestriction = useCallback(
    async function ClearAccessRestriction() {
      setState(SessionStatus.INITIALIZING);
      await clearRestriction();
      navigate('/');
      await client?.clearStore();
      reload();
    },
    [clearRestriction, setState, client, navigate, reload]
  );

  useEffect(() => {
    // Dont update if sessions are equal
    if (isEqual(session || null, cachedSession || null)) return;

    // Dont update if session is disabled
    if (status === SessionStatus.DISABLED) return;

    // Don't set the session when loading from the cache
    // if the cached session is empty
    debug('[Apollo::Session::SessionProvider] Incoming session and cached session dont match, updating context', {
      status,
      cachedSession,
      session
    });
    if (session) {
      setSession(session || null);
    }
  }, [cachedSession, session, setSession, status]);

  const value: ISessionContext = useMemo(
    () => ({
      called,
      session: state.status === SessionStatus.NONE ? null : query?.session,
      reload,
      logout,
      clearAccessRestriction: onClearRestriction
    }),
    [logout, called, onClearRestriction, reload, query?.session, state.status]
  );

  return <SessionContext.Provider {...{ value }}>{children}</SessionContext.Provider>;
}
